.weather {
    font-family: Arial, sans-serif;
    color: white;
    padding: 10px;
    border-radius: 10px;
    width: 450px;
    text-align: center;
    font-size: 21px;
}

.weather h2 {
    margin: 0;
    font-size: 1.5em;
}

.weather p {
    margin: 5px 0;
}

.weather img {
    vertical-align: middle;
}