/* ProjectShowcase.css */
/* For larger screens (forcing 3 columns) */
@media (min-width: 1100px) {
    .projects-grid {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

.project-showcase {
    padding: 20px;
    text-align: center;
}

.filters {
    margin-bottom: 20px;
}

.filters button {
    margin: 0 5px;
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.filters button:hover {
    background-color: #0056b3;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.project-card {
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-card img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 15px;
}

.project-card h3 {
    margin: 10px 0;
    font-size: 1.2em;
}

.project-card p {
    color: #555;
}

.tags .tag {
    display: inline-block;
    background-color: #eee;
    color: #333;
    padding: 5px 10px;
    margin: 5px 5px 0 0;
    border-radius: 20px;
    font-size: 0.9em;
}